import checkIcon from "@public/images/check-icon.svg";
import { useEffect, useState } from "react";

import type { ProductShort } from "@/api/types.ts";
import { FormLink } from "@/components/FormLink.tsx";
import { cn } from "@/utils/cn";
import { POST_HOG_FEATURES_FLAG } from "@/utils/constants.ts";
import { findProductWithFunnelTag } from "@/utils/findProductWithFunnelTag.ts";
import { getPrice } from "@/utils/getPrice.ts";
import { ROUTER } from "@/utils/routes.ts";

type BadgeColor = "green" | "white";

const badgeColorClasses: Record<BadgeColor, string> = {
  green: "bg-light-green text-white",
  white: "bg-white text-dark-blue",
};

type BusinessPlanCardProps = {
  widget?: boolean;
  title: string;
  badgeText: string;
  badgeColor?: BadgeColor;
  products: ProductShort[];
  priceDescription: string;
  features: string[];
  showOriginalPrice?: boolean;
  buttonId?: string;
};

export const BusinessPlanCard = ({
  widget,
  title,
  badgeText,
  badgeColor = "green",
  products,
  priceDescription,
  features,
  showOriginalPrice = true,
  buttonId,
}: BusinessPlanCardProps) => {
  const [product, setProduct] = useState<ProductShort | null>(null);
  const price = product ? getPrice(product, "price") : null;
  const originalPrice = product ? getPrice(product, "original_price") : null;
  const unregisteredLink = widget ? ROUTER.widget : ROUTER.registration;
  const registeredLink = widget ? ROUTER.widget : ROUTER.form;

  useEffect(() => {
    const funnel =
      (window.posthog.getFeatureFlag(
        POST_HOG_FEATURES_FLAG.funnel,
      ) as string) ?? "A23";

    setProduct(findProductWithFunnelTag(products, funnel));
  }, []);

  return (
    <div className="shadow-gray">
      <div className="bg-black-blue p-6">
        <h4
          className={cn(
            "text-white text-base leading-[1.33] font-bold text-center",
            "md:text-xl md:leading-none",
          )}
        >
          {title}
        </h4>
      </div>

      <div
        className={cn(
          badgeColorClasses[badgeColor],
          "py-[11px] px-5 text-md leading-[1.2] text-center font-bold",
          "md:text-xl md:leading-none",
        )}
      >
        {badgeText}
      </div>

      <div
        className={cn(
          "flex flex-col items-center pt-4 pb-[54px] px-4",
          "md:px-2",
        )}
      >
        <div
          className={cn(
            "flex items-center justify-center gap-x-1",
            "gap-x-[10px] min-h-[56px] min-w-[250px]",
            {
              "animate-pulse bg-zinc-400 rounded-md": !product,
            },
          )}
        >
          {showOriginalPrice && Number(originalPrice) > Number(price) && (
            <p
              className={cn(
                "line-through text-[#848484] text-md leading-[1.5] font-semibold",
                "md:text-xl",
              )}
            >
              {originalPrice}
            </p>
          )}

          <p
            className={cn(
              "text-light-blue font-semibold text-[40px] leading-[1.4]",
              "md:text-[48px] md:leading-[1.16]",
            )}
          >
            {price}
          </p>
        </div>

        <p className="text-md leading-none text-dark-blue mt-2">
          {priceDescription}
        </p>

        {features.length > 0 && (
          <ul className="mt-7 max-w-[360px] space-y-3">
            {features.map((feature, index) => (
              <li key={index} className="font-bold flex items-start gap-2">
                <img
                  className="size-[19px] mt-[2px]"
                  src={checkIcon.src}
                  width={19}
                  height={19}
                  alt=""
                />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        )}

        <FormLink
          className="text-md mt-7"
          registeredLink={registeredLink}
          unregisteredLink={unregisteredLink}
          id={buttonId}
          target="_top"
        >
          Get started
        </FormLink>
      </div>
    </div>
  );
};
